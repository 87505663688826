import React, { useEffect, useState } from 'react'
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { API_URL } from "../../utils/constants";
import axios from 'axios'
import { CSVLink } from "react-csv";
import Button from '@mui/material/Button';
import DownloadIcon from '@mui/icons-material/Download';
import moment from 'moment'
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Layout from "../../components/layout";



function admin() {
    const [orders, setOrders] = useState([{ id: 1, clientOrderId: 1, username: 'xavier' }])
    const [loader, setLoader] = useState(false)
    const [dataCsv, setCsvData] = useState([])
    const columns: GridColDef[] = [
        { field: 'clientOrderId', headerName: 'ID', width: 140 },
        { field: 'userName', headerName: 'User Name', width: 130 },
        { field: 'carName', headerName: 'Car name', width: 150 },
        { field: 'userPhoneNumber', headerName: 'Client Phone', width: 150 },
        { field: 'approved', headerName: 'Approval', width: 100 },
        { field: 'statusChangeDate', headerName: 'Status changed date', width: 170 },
        { field: 'reservationOption', headerName: 'Reservation', width: 130 },

        { field: 'pickUpAddress', headerName: 'Pick Up Address', width: 150 },
        { field: 'destination', headerName: 'Destition', width: 150 },

        {
            field: 'days',
            headerName: 'Days',
            width: 160,
        },
        {
            field: 'daysInTown',
            headerName: 'Days In Town',
            type: 'number',
            width: 90,
        },
        { field: 'daysOutOfTown', headerName: 'Days Out Of Town', type: 'number', width: 140 },
        {
            field: 'totalDays',
            headerName: 'Total Days',
            type: 'number',
            width: 110,
        },

        {
            field: 'handledBy',
            headerName: 'Handled By',
            description: 'This column has a value getter and is not sortable.',
            width: 160,

        },
        {
            field: 'amount',
            headerName: 'Amount',
            type: 'number',
            width: 90,
        },
        {
            field: 'startDate',
            headerName: 'Start Date',
            description: 'Date when car should.',
            sortable: false,
            width: 160,

        },
        {
            field: 'endDate',
            headerName: 'End Date',
            description: 'Date when car should be returned.',
            sortable: false,
            width: 160,

        },
        {
            field: 'created_at',
            headerName: 'Created At',
            description: 'This column has a value getter and is not sortable.',
            sortable: true,
            width: 270,
            type: 'date'

        },
    ];

    const getOrders = async () => {

        setLoader(true)
        await axios
            .get(`${API_URL}/getOrders`)
            .then(async (response) => {
                if (response.status === 200) {
                    await setOrders(response.data)
                    csvData(response.data)
                    setLoader(false)
                }
            })
            .catch((error) => {
                setLoader(false)

            });
    }

    const csvData = (orders: Array<object>) => {
        orders.map((order) => {
            order.reservationOption = order.reservationOption === "i" ? "InTown" : order.reservationOption === "o" ? "OutOfTown" :
                order.reservationOption === "b" ? "Both" : ""

            order.approved = (order.approved == null) ? 'pending' : order.approved ? true : false
            order.statusChangeDate = order.statusChangeDate ? moment(order.statusChangeDate).format('lll') : ''
            order.startDate = moment(order.startDate).format('ll')
            order.endDate = moment(order.endDate).format('ll')
            order.created_at = moment(order.created_at._seconds * 1000).format('llll')

            return order
        })

        let ordersKeys: Array<string> = []
        let myCsvData = [ordersKeys]
        columns.map((elmt) => {
            ordersKeys.push(elmt.field)
        })
        orders.map((order => {
            myCsvData.push(ordersKeys.map((key) => {
                if (typeof order[key] !== 'string') {
                    return String(order[key])
                }
                return order[key]
            }))
        }))
        setCsvData(myCsvData)

    }


    useEffect(() => {
        getOrders()
        const auth = getAuth();
        onAuthStateChanged(auth, (user) => {
            if (user) {
                // User is signed in, see docs for a list of available properties
                // https://firebase.google.com/docs/reference/js/firebase.User
                const uid = user.uid;
                // alert('yes')

                // ...
            } else {
                // User is signed out
                if (typeof window !== "undefined") location.replace('/book-a-car')
                // ...
            }
        });
    }, [])


    return (
        <>
            <Layout>
                <div style={{ marginTop: "20px" }}>
                    <h2 style={{ textAlign: 'center', padding: "2%" }}>Order records
                        <div style={{ float: 'right' }}>
                            <CSVLink filename={"Order_records.csv"} data={dataCsv}>
                                <Button variant="contained" color="success" size="small" endIcon={<DownloadIcon />}>
                                    Download CSV
                                </Button>
                            </CSVLink>
                        </div>
                    </h2>
                </div>

                <div style={{ margin: '0 auto', height: 462, width: '98%' }}>
                    <DataGrid
                        initialState={{
                            sorting: {
                                sortModel: [{ field: 'clientOrderId', sort: 'desc' }],
                            },
                        }}
                        rows={orders}
                        columns={columns}
                        getRowId={row => row.id}
                        pageSize={10}
                        loading={loader}
                        density='compact'
                        sx={{ justifyContent: 'center' }}
                    />
                </div>
            </Layout>
        </>

    )
}

export default admin